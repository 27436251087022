@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}

body {
  font-family: "Montserrat";
}

/*-- Default font size --*/
html {
  font-size: 16px;
}

@media (max-width: 320px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 321px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 375px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 2000px) {
  html {
    font-size: 20px;
  }
}

.jkoa-blue-btn {
  background: #4f79ff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 50px !important;
  opacity: 1;
  color: #e9edf9 !important;
  font-family: "Montserrat-Medium";
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  border-color: white;
  min-height: 40px;
}

.jkoa-blue-btn:hover {
  box-shadow: 0px 8px 8px #00000033;
  background-color: #0d44fa !important;
}

.jkoa-transparent-btn {
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 50px !important;
  opacity: 0.8;
  color: #e9edf9 !important;
  font-family: "Montserrat-Medium";
  padding: 0.7rem 2rem !important;
  text-transform: uppercase;
  border-color: rgb(157, 221, 250) !important;
}

.jkoa-transparent-btn:hover {
  box-shadow: 0px 8px 8px #00000033;
  opacity: 1;
}

.jkoa-transparent-blue-btn {
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 50px !important;
  opacity: 1;
  color: #e9edf9 !important;
  font-family: "Montserrat-Medium";
  padding: 0.7rem 2rem !important;
  text-transform: uppercase;
  border-color: #4f79ff !important;
}

.jkoa-transparent-blue-btn {
  box-shadow: 0px 8px 8px #00000033;
  color: #e9edf9;
  background-color: #0d44fa !important;
}


.jkoa-underline-btn {
  background: transparent 0% 0% no-repeat !important;
  opacity: 1;
  color: black !important;
  font-family: "Montserrat-Medium";
  text-transform: uppercase;
  border-color: white !important;
  text-decoration: underline !important;
  border: 1px transparent !important;
}

.jkoa-card-blue-btn {
  background: #4f79ff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 50px !important;
  opacity: 1;
  color: #e9edf9 !important;
  font-family: "Montserrat-Medium";
  padding: 8.8px 25px !important;
  text-transform: uppercase;
  border-color: #4f79ff;
}

.jkoa-card-blue-btn:hover {
  background-color: #0d44fa !important;
  box-shadow: 0px 8px 8px #00000033;
}

.jkoa-card-transparent-blue-btn {
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 50px !important;
  opacity: 1;
  color: #0d44fa !important;
  font-family: "Montserrat-Medium";
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  border-color: #0d44fa !important;
}

.jkoa-card-transparent-blue-btn:hover {
  color: #e9edf9 !important;
  box-shadow: 0px 8px 8px #00000033;
  background: #0d44fa 0% 0% no-repeat padding-box !important;
}

.home-section-container {
  margin-bottom: 3rem;
}

.home-section-small-heading {
  font-family: "Montserrat-Medium";
  opacity: 1;
  letter-spacing: -1.5px;
  font-size: 1.8rem;
}

.home-section-main-heading {
  letter-spacing: -3px;
  font-family: "Montserrat";
  text-transform: uppercase;
  opacity: 1;
  font-size: 3.7rem;
}

.progress {
  background-color: #4F79FF !important;
}

.progress-bar {
  background-color: white !important;
}

.section-spacer {
  margin: 3rem 0 3rem 0;
}

.jkoa-productDetail-specialOffer {
  height: 40px;
  width: 83px;
  background-color: #65bf46 !important;
  color: white;
  position: absolute;
  top: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  line-height: 40px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  text-align: left;
  padding-left: 5px;
  z-index: 99;
}

.jkoa-productDetail-newArrival {
  height: 40px;
  width: 83px;
  background-color: #0084bc !important;
  color: white;
  position: absolute;
  top: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 40px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  text-align: left;
  padding-left: 5px;
  z-index: 99;
}

.jkoa-productListing-specialOffer {
  height: 40px;
  width: 31%;
  background-color: #65bf46 !important;
  color: white;
  position: absolute;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 40px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding-left: 7px;
}

.jkoa-productListing-newArrival {
  height: 40px;
  width: 31%;
  background-color: #0084bc !important;
  color: white;
  position: absolute;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  line-height: 40px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding-right: 7px;
}