@import "../assets/scss/defaultTheme.css";

.header {
    width: 100%;
    position: fixed;
    z-index: 999;
}
.outer {
    position: relative;
    height: 100px;
    top: 71px;
}