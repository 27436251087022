.navBarDefault{
    width: 100%;
    background-color: #FFFFFF;
    top: 0px;
    /* padding-left: 100px;
    padding-right: 100px; */
    /* padding-top: 40px; */
    /* padding: 1rem; */
    opacity: 1;

}
.logo{
    opacity: 1;
}
.navBarMainStyle{
    text-align: left;
    font-family: 0.938rem/1.188rem "Montserrat-Medium" !important;
    letter-spacing: 0.024rem;
    color: #313C5E;
    margin-right: 15px;
}


.dropdown:hover {
display: block !important;
}

.dropdownBoxContainer{
    margin-top: 10px;
    width: 400px;
    height: 335px;
}

.dropdownBox{
    position: absolute;
    list-style: none;
    height: 335px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
}
.navfull{
    width: 100%;
    /* opacity: 100%; */
}

/* search bar */
.navSearchContainer{
    /* text-align: right !important;
    padding-left: 50% !important; */
    /* letter-spacing: 0.024rem; */
    color: #313C5E !important;
    margin-left: auto;
    cursor: pointer;
}

.navSearchBarInput {
    padding: 5px;
    width: 20px;
    /* height: 80px; */
    background: none;
    border: 2px solid #4F79FF;
    border-radius: 50px;
    box-sizing: border-box;
    /* font-size: 26px; */
    outline: none;
    transition: .5s;
    opacity: 0;
    text-transform: uppercase;
    margin-right: 10px;
}

.navSearchBar:hover .navSearchBarInput{
    width: 250px;
    background: #fbfaff;
    border-radius: 50px ;
    opacity: 1;
}

.navSearchBarInput:focus{
    width: 250px;
    background: #fbfaff;
    border-radius: 50px ;
    opacity: 1;
}

.navSearchBarInput:focus i{
    display: none;
}

/* .navSearchBar:hover i{
    display: none;
} */

/* Mobile search bar */

.searchBarMobileContainer{
    background-color: transparent;
}

.searchBarMobile{
    padding: 5px;
    width: 100%;
    background: none;
    border: 2px solid #4F79FF;
    border-radius: 50px;
    box-sizing: border-box;
    outline: none;
    text-transform: uppercase;
}
.searchContainerMobile{
    padding: 5px;
    width: 100%;
    background: none;
    box-sizing: border-box;
    outline: none;
    text-transform: uppercase;
    max-height: 200px;
    overflow-y: auto;
}

.searchContainer{
    width: 250px;
    max-height: 150px;
    background-color: #F8F9FA;
    display: block;
    position: absolute;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-y: auto;
    padding-bottom: 10px;
}
.searchContainer::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
.searchContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ebbe8f; 
    border-radius: 10px;
  }
   
  /* Handle */
.searchContainer::-webkit-scrollbar-thumb {
    background: #4f79ff; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
.searchContainer::-webkit-scrollbar-thumb:hover {
    background: #1b4df1; 
}

.searchResult{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}
.searchResultMobile{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}
.searchResult:hover{
    box-shadow: 0px 0px 1px #00000033;
}
/* Fading animation */
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @-webkit-keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }

.submenuTitle{
    padding-right: 15px !important;
}