/* @import "../../assets/scss/defaultTheme.css"; */

.footerDefault {
    bottom: 0rem;
    width: 100%;
    z-index: 1020;
    font-size: 0.75rem;
    color: #ffffff;
    font-family: "Montserrat-Medium";
    /* position: relative; */
}
.backgourndGradient {
    background: rgb(10, 13, 20);
    background: linear-gradient(42deg, rgba(10, 13, 20, 1) 0%, rgba(47, 57, 92, 1) 100%);
}
/* .footerDefault:after {
    content: "JKOA";
    position: absolute;
    top: 0;
    left: 0;
    text-shadow: 0rem 0rem 0.625rem #00000029;
    opacity: 0.08 !important;
    font-size: 35rem !important;
    width: 100%;
    text-align: center;
    font-family: "Montserrat-Medium" !important;
    color: #4a577d;
    z-index: -1;
} */
.footerRow {
    margin: 0 5rem !important;
}
.footerLinks {
    padding: 5.625rem 0;
    font-size: 1.125rem;
}
.footerLlinkHeading {
    padding-bottom: 1rem;
    font-family: "Montserrat-Bold";
    font-size: 1.25rem;
    text-transform: uppercase;
}
.footerLinkItem {
    padding-bottom: 0.4rem;
    color: #aeaeae;
}
.footerLinkItem:hover {
    color: #f9fafb38;
    cursor: pointer;
    font-family: "Montserrat-Medium";
    font-size: 1rem;
}
.footerimg {
    display: flex;
    /* padding: 0 7rem 2rem 0 !important; */
}
.contactRow {
    padding: 0 5rem !important;
    align-items: center;
}
.locatorIcon {
    height: 2.4375rem;
    width: 2.4375rem;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 3.4375rem;
    background: #b8b8b8;
    color: black;
    box-shadow: 7px 11px 13px #0000000f;
    margin: 0 1rem 0 0;
    padding: 1rem;
}
.socialMediaCol {
    display: flex;
}
.fotterBottom {
    padding: 2rem 0 !important;
    color: #aeaeae;
    opacity: 0.5;
}
.cotactDetailsDiv {
    align-items: center;
    display: flex;
    justify-content: center;
}
.seperator {
    margin-top: 1rem !important;
}
.contactDetails {
    /* padding-bottom: 0.4rem; */
    color: #aeaeae;
    display: block;
}
.conceptBy {
    text-align: right;
}
