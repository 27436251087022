.getInTouchLable {
    padding-bottom: 0.4rem;
    color: #aeaeae;
}
.formHeading {
    padding-bottom: 1rem;
    font-family: "Montserrat-Bold";
    font-size: 1.25rem;
    text-transform: uppercase;
}
.formGroupGetInTouch {
    margin-bottom: 1rem;
}
.formControlGetInTouch {
    height: 2.8125rem;
    background: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-left: 0.5rem;
    width: 100%;
    color: #aeaeae;
    border-color: rgb(118, 118, 118) !important;
}
.formControlGetInTouch:disabled {
    cursor: not-allowed;
}
.formLable {
    color: rgb(133, 133, 133);
}
.submitBtn {
    background: #4f79ff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 5px 5px #00000033;
    border-radius: 50px !important;
    opacity: 1;
    color: #e9edf9 !important;
    font-family: "Montserrat-Medium";
}
