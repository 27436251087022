@import "../../../assets/scss/defaultTheme.css";

.message-box .modal-content .modal-body .message-box-header-info, .message-box .modal-content .modal-body .message-box-header-success, .message-box .modal-content .modal-body .message-box-header-warning, .message-box .modal-content .modal-body .message-box-header-error {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 2px;
    color: white;
    border-top-right-radius: 1.875rem;
}
.message-box .modal-content {
    border: 0px;
    background-color: #eff8ec;
    border-radius: 1.875rem 1.875rem !important;
    overflow: hidden;
}
.message-box .modal-content #id_message_box_header_icon {
    float: left;
    font-size: 1.3333rem;
    padding-right: 0.6667rem;
}
.message-box .modal-content .modal-body {
    padding: 0 !important;
    border-top-right-radius: 1.875rem;
}
.message-box .modal-content .modal-body .message-box-header-info {
    background-color: #5c5c5c;
}
.message-box .modal-content .modal-body .message-box-header-success {
    background-color: #3bb35b;
}
.message-box .modal-content .modal-body .message-box-header-warning {
    background-color: #e2e83f;
}
.message-box .modal-content .modal-body .message-box-header-error {
    background-color: #d14a43;
}
.message-box .modal-content .modal-body .message-box-body {
    margin: 0;
    padding-top: 10px;
}


.new-btn-primary {
    background-color: #3bb35b;
    color: white;
}
.new-btn-primary .disabled {
    /* opacity: 0.7; */
    background: #7ab569;
    cursor: not-allowed;
}
.new-btn-primary .focus {
    background-color: "#2f9512";
}

.new-btn-secondary {
    background-color: #5c5c5c;
    color: white;
}
.new-btn-secondary .disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

.new-btn-warning {
    background-color: #e2e83f;
    color: white;
}
.new-btn-warning .disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
