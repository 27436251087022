/* @import "../../../../assets/scss/defaultTheme.css"; */

.footerDefault {
    bottom: 0rem;
    width: 100%;
    z-index: 1020;
    font-size: 0.75rem;
    color: #ffffff;
    font-family: "Montserrat-Medium";
}

.backgourndGradient {
    background: rgb(10, 13, 20);
    background: linear-gradient(42deg, rgba(10, 13, 20, 1) 0%, rgba(47, 57, 92, 1) 100%);
}
.footerRow {
    padding: 1rem 1rem 1rem 1rem !important;
    margin: 0 !important;
}
.accordianTab {
    text-align: left;
    display: block;
}
.tabs {
    overflow: hidden;
    font-weight: normal !important;
}
.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}
.tabLabel {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}
.tabLabel::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}
.tabContent {
    max-height: 0;
    padding: 0 1em;
    color: white;
    transition: all 0.35s;
}
.tabClose {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
}
.footerLinkHolder {
    padding-bottom: 1rem;
}
.footerLinkHeading {
    padding-bottom: 1rem;
    font-family: "Montserrat-Bold";
    font-size: 1.25rem;
    text-transform: uppercase;
}
.rd {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
input:checked + .tabLabel::after {
    transform: rotate(90deg);
}
input:checked ~ .tabContent {
    max-height: 100vh;
}
.tabLabel {
    font-weight: normal !important;
    padding: 0 !important;
    padding-bottom: 0.5rem !important;
}
.footerLinkItem {
    padding-bottom: 0.4rem;
    color: #aeaeae;
}
.footerLinkItem:hover {
    color: #f9fafb38;
    cursor: pointer;
    font-family: "Montserrat-Medium";
    font-size: 1rem;
}
.formGroupGetInTouch {
    margin-bottom: 1rem;
}
.formLable {
    color: rgb(133, 133, 133);
}
.footerimg {
    display: flex;
    padding-bottom: inherit;
    /* padding: 0 7rem 2rem 0 !important; */
}
.footerlogoName {
    color: #aeaeae;
    font-size: 0.938rem;
}
.locatorIcon {
    height: 2.4375rem;
    width: 2.4375rem;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 3.4375rem;
    background: #b8b8b8;
    color: black;
    box-shadow: 7px 11px 13px #0000000f;
    margin: 0 2rem 0 0;
    padding: 1rem;
}
.socialMediaCol {
    /* justify-content: center; */
    align-items: center;
    display: flex;
}
.footerBottom {
    padding-bottom: 3rem !important;
}
.contactDetails {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-bottom: 2rem;
}
.copyRights {
    padding-bottom: 1rem;
    color: #aeaeae;
    opacity: 0.5;
}
